import axios from 'axios';
import Cookies from 'js-cookie';
import { action, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import Router from 'next/router';
import { Client, DisplaySettings, IUserAccount, Links, Member, User } from 'types';
import AppController from './base/App.controller';
import ModalStore from './components/common/modals/ModalStore';
import { TOKEN } from './constants/banks';
import { getScope } from './scopes';
import { decryptClient } from 'helpers/quick-hash/quick-hash';
import formatEmailIfNotExistsInFirebase from 'helpers/format-email-if-not-exists-in-firebase/formatEmailIfNotExistsInFirebase';

const firebase = require('./firebase');
const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({ enforceActions: 'observed' });

let store: AppStore | null = null;

// This store should only contain data that will be shared across pages.
export class AppStore {
    errorStore = new ModalStore();

    @observable
    user: User = {};
    @observable
    member: Member = {};
    @observable
    links: Links = null;
    @observable
    host = '';
    @observable
    scope = TOKEN;
    @observable
    client: Client = {};
    @observable
    pathname = '';
    @observable
    displaySettings: DisplaySettings = {};
    @observable
    refundsTokenSigningKeyIds: string[] = [];
    @observable
    oktaGroups: string[] = [];
    @observable
    userAccounts: IUserAccount[] = [];

    @action
    setUser(user: User) {
        this.user = user;
        this.saveToStorage('user', user);
    }

    @action
    setUserAccounts(userAccounts: IUserAccount[]) {
        this.userAccounts = userAccounts;
        this.saveToStorage('userAccounts', userAccounts);
    }

    @action
    setMember(member: Member) {
        this.member = member;
        this.saveToStorage('member', member);
    }

    @action
    setLinks(links: Links) {
        this.links = links;
    }

    @action
    setHost(host: string) {
        this.host = host;
        this.scope = getScope(host);
    }

    @action
    setClient(client: Client) {
        this.client = client;
        this.saveToStorage('client', client);
    }

    @action
    setPath(pathname: string) {
        this.pathname = pathname;
    }

    @action
    setDisplaySettings(displaySettings: DisplaySettings) {
        this.displaySettings = displaySettings;
        this.saveToStorage('displaySettings', displaySettings);
    }

    @action
    setRefundsTokenSigningKeyIds(keyIds: string[]) {
        this.refundsTokenSigningKeyIds = keyIds;
    }

    @action
    setOktaGroups = (groups: string[]) => {
        this.oktaGroups = [...groups];
    };

    @action
    handleSignOut = flow(
        function* (this: AppStore) {
            try {
                Cookies.remove('lastRefundTimestamp');
                window.sessionStorage.clear();
                yield Promise.all([
                    axios.post('/api/auth/sessionLogout'),
                    firebase.auth().signOut(),
                    Router.push('/signin'),
                ]);
                this.clearSession();
            } catch (e) {
                console.error(e);
            }
        }.bind(this),
    );

    get isServer() {
        return typeof window === 'undefined';
    }

    saveToStorage(key: string, item: any) {
        if (!this.isServer) {
            window.sessionStorage.setItem(key, JSON.stringify(item));
        }
    }
    @action
    clearSession = () => {
        this.setUser({});
        this.setMember({});
        this.setLinks(null);
        this.setClient({});
        this.setPath('');
        this.setUserAccounts([]);
        AppController.clearStorageSession();
    };

    // from menu bar switch
    @action
    handleSelectUserAccount = flow(
        function* (selectedAccount: IUserAccount): any {
            try {
                Router.push('/home');
                const { email, id } = selectedAccount;
                const _signInFirebaseWithMail = async (
                    selectedAccountEmail: string,
                    scope: string,
                ) => {
                    const storedPassword = await decryptClient(
                        window.sessionStorage.getItem('shared-ua') as string,
                    );
                    const firebaseEmail = await formatEmailIfNotExistsInFirebase(
                        selectedAccountEmail,
                        scope,
                        storedPassword,
                    );
                    await firebase.auth().signInWithEmailAndPassword(firebaseEmail, storedPassword);
                    window.sessionStorage.setItem('active-ua', selectedAccountEmail);
                    return firebase.auth().currentUser.getIdToken();
                };
                const newToken = yield _signInFirebaseWithMail(email, this.scope);
                const rememberMe = window.sessionStorage.getItem('rememberMe');

                window.sessionStorage.setItem('email', email);

                yield axios.post(
                    '/api/auth/signin',
                    {
                        token: newToken,
                        email: window.sessionStorage.getItem('email'),
                        rememberMe: rememberMe === 'true',
                        identityId: id,
                    },
                    { withCredentials: true },
                );

                this.clearSession();
                window.sessionStorage.setItem('email', email);
                Router.reload();
            } catch (e) {
                console.error(e);
            }
        }.bind(this),
    );
}

export function getAppStore(getNew = false): AppStore {
    if (isServer || getNew) {
        return new AppStore();
    } else {
        if (store === null) {
            store = new AppStore();
        }
        return store;
    }
}

export default new AppStore();
